import React from 'react';

function Hours() {
  // 時間帯データ
  const timeSlots = [
    { label: "昼の部", time: "14:00〜", data: ["△", "△", "△", "△", "△", "△", "※"] },
    { label: "夜の部", time: "18:30〜", data: ["△", "△", "△", "△", "△", "△", "※"] },
  ];

  // 曜日データ
  const days = ["月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日", "日曜日／祝日"];
  const shortDays = ["月", "火", "水", "木", "金", "土", "日祝"];
  const colors = ["bg-lime-700", "bg-lime-700", "bg-lime-700", "bg-lime-700", "bg-lime-700", "bg-blue-700", "bg-red-700"];

  return (
    <section id="hours" className="container mx-auto max-w-screen-lg pt-36 pb-12">
      <h2 className="glowing-text font-semibold text-4xl md:text-6xl mb-6 text-center">稽古日・時間</h2>

      {/* PC用の横型テーブル */}
      <div className="hidden md:block p-4 bg-white shadow-md rounded-md">
        <table className="table-auto w-full text-center border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 border border-gray-300"></th>
              {days.map((day, index) => (
                <th key={index} className={`px-2 md:px-4 py-2 border border-gray-300 text-white ${colors[index]}`}>
                  <span className="hidden md:inline">{day}</span>
                  <span className="md:hidden">{shortDays[index]}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timeSlots.map((row, i) => (
              <tr key={i}>
                <td className="font-semibold px-2 md:px-4 py-2 border border-gray-300">
                  {row.label}<br/><span className="text-sm font-normal">{row.time}</span>
                </td>
                {row.data.map((cell, j) => (
                  <td key={j} className="text-2xl px-2 md:px-4 py-2 border border-gray-300">{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <ul className="text-sm mt-2 space-y-3 text-center">
          <li>「⚫︎」は現在生徒さんがいらっしゃる箇所です</li>
          <li>「△」は現在生徒さんはおりませんが、可能です</li>
          <li>「※」はイベント時にはお稽古不可となります</li>
          <li>上記以外（午前中など）をご希望の場合はご相談ください</li>
        </ul>
      </div>

      {/* スマホ用の縦型テーブル */}
      <div className="md:hidden p-4 bg-white shadow-md rounded-md">
        <table className="table-auto w-full text-center border border-gray-300">
          <thead>
            <tr>
              <th className="px-2 py-2 border border-gray-300"></th>
              <th className="px-2 py-2 border border-gray-300">午前の部<br/><span className="text-sm">10:00〜</span></th>
              <th className="px-2 py-2 border border-gray-300">午後の部<br/><span className="text-sm">14:00〜</span></th>
            </tr>
          </thead>
          <tbody>
            {days.map((day, index) => (
              <tr key={index}>
                <td className={`text-white font-semibold px-2 py-2 border border-gray-300 ${colors[index]}`}>{day}</td>
                <td className="text-2xl px-2 py-2 border border-gray-300">{timeSlots[0].data[index]}</td>
                <td className="text-2xl px-2 py-2 border border-gray-300">{timeSlots[1].data[index]}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <ul className="text-xs mt-2 space-y-2 text-center">
          <li>「⚫︎」は現在生徒さんがいらっしゃる箇所です</li>
          <li>「△」は現在生徒さんはおりませんが、可能です</li>
          <li>「※」はイベント時にはお稽古不可となります</li>
          <li>上記以外（午前中など）をご希望の場合はご相談ください</li>
        </ul>
      </div>
    </section>
  );
}

export default Hours;
