import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function HeadLine() {
  return (
    <div>
      {/* Trial */}
      <Link smooth to="/#trial">
        <div className="bg-gray-100 opacity-95 flex flex-col h-full pt-2 md:pt-6 pb-2 md:pb-6 text-center justify-center items-center">
          <h1 className="tracking-widest font-semibold text-xl md:text-4xl flex items-center">
            無料体験入門実施中
            <span className="ml-4 text-blue-500 text-sm md:text-2xl hover:text-blue-300 transition duration-300">{'>>'}</span>
          </h1>
          <p className='text-xs md:text-base tracking-widest mt-1 md:mt-2'>まずはお気軽に、実際の稽古風景を体験してみましょう</p>
        </div>
      </Link>

      {/* HeadLine */}
      <Link
        smooth to="/#plans"
        className="block relative w-full bg-cover bg-center pt-12 pb-16"
      >
        {/* オーバーレイ */}
        <div className="absolute inset-0 bg-black opacity-40"></div>

        {/* コンテンツ */}
        <div className="relative flex flex-col h-full p-8 text-center justify-center items-center">
          <h1 className="text-white text-4xl md:text-6xl font-bold glowing-text leading-relaxed md:leading-loose">
            <span className="block sm:inline">2025年より</span>
            <span className="block sm:inline">生徒さん募集開始<br /></span>
            <span className="block sm:inline">本格的な茶道を</span>
            <span className="block sm:inline">始めてみませんか</span>
          </h1>
          <p className="text-white text-4xl md:text-7xl font-semibold glowing-text mt-16">
            月額 8,000円 〜
          </p>
          {/* ボタン */}
          <Link
            smooth to="/#plans"
            className="mt-8 px-6 py-2 text-white rounded-full bg-blue-600 hover:bg-blue-500 transition duration-300"
            onClick={(e) => e.stopPropagation()} // 背景リンクとの競合を防ぐ
          >
            プラン一覧へ
          </Link>
        </div>
      </Link>

      {/* Heading */}
      <section className="py-12 px-6 bg-lime-900 opacity-95 text-white text-center">
        {/* 説明文部分 */}
        <p className="text-lg glowing-text sm:text-xl mb-12">
          <span className="block sm:inline">様々な料金プランをご用意。</span>
          <span className="block sm:inline">気軽に始めたい人も、</span>
          <span className="block sm:inline">本格的に極めたい人も。</span>
          <span className="block sm:inline">日々の生活に伝統的なアクセントを。</span>
        </p>

        {/* サービス内容リスト */}
        <ul className="text-left glowing-text space-y-3 text-base md:text-lg mx-auto max-w-md">
          <li>✅ 年会費なし、安心の月額制</li>
          <li>✅ 伝統的な茶室でのお稽古が可能</li>
          <li>✅ 本格的な茶道具を使ってのお稽古ができる</li>
          <li>✅ お稽古でも本格的な和菓子が食べられる</li>
          <li>✅ お稽古でも本格的な薄茶・濃茶が飲める</li>
          <li>✅ 入会期間の縛りなし、いつでも退会可能</li>
          <li>✅ オプションでお茶会などへの参加も可能</li>
        </ul>

        {/* 注意書き */}
        <p className="text-xs md:text-sm text-gray-300 mt-8">
          ※ お支払いは12ヶ月分ご一括となりますが、ご退会時は月割りで返金いたします
        </p>
      </section>
    </div>
  );
}

export default HeadLine;
