import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FaInstagram } from 'react-icons/fa';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="bg-black opacity-90 p-2 md:p-4 fixed w-full z-10 top-0">
        <div className="container mx-auto flex justify-between items-center max-w-screen-lg">
          <div className="flex items-end px-2">
            <Link smooth to="/" className="tracking-widest text-white glowing-text text-2xl md:text-3xl font-semibold no-underline hover:no-underline">
              静亮庵
            </Link>
            <span className="text-white glowing-text ml-4 text-sm md:text-lg align-baseline">江戸千家渭白流</span>
          </div>

          {/* モバイル表示のアイコン + ハンバーガーメニュー */}
          <div className="flex items-center md:hidden">
            <a href="https://www.instagram.com/seiryo_kishida/" target="_blank" rel="noopener noreferrer" className="text-3xl text-pink-600 hover:text-pink-700 mr-4 mt-2">
              <FaInstagram />
            </a>
            <button className="text-white text-4xl focus:outline-none" onClick={toggleMenu}>
              {isOpen ? '✕' : '☰'}
            </button>
          </div>

          {/* PC用のナビゲーション */}
          <nav className="hidden md:flex items-center text-white">
            <Link smooth to="/#plans" className="mr-4">料金</Link>
            <Link smooth to="/#events" className="mr-4">茶会</Link>
            <Link smooth to="/#about" className="mr-4">紹介</Link>
            <Link smooth to="/#hours" className="mr-4">稽古日</Link>
            <Link smooth to="/#belongings" className="mr-4">持ち物</Link>
            <Link smooth to="/#access" className="mr-4">アクセス</Link>
            {/* PC版のInstagramアイコン */}
            <a href="https://www.instagram.com/seiryo_kishida/" target="_blank" rel="noopener noreferrer" className="text-2xl text-pink-600 hover:text-pink-700">
              <FaInstagram />
            </a>
          </nav>
        </div>

        {/* モバイル用メニュー */}
        {isOpen && (
          <div className="fixed inset-0 bg-black text-white glowing-text flex flex-col justify-center items-center text-4xl">
            <button
              className="absolute top-4 right-4 text-4xl focus:outline-none"
              onClick={toggleMenu}
            >
              ✕
            </button>
            <Link smooth to="/#plans" className="my-4 no-underline hover:underline" onClick={toggleMenu}>料金プラン</Link>
            <Link smooth to="/#events" className="my-4 no-underline hover:underline" onClick={toggleMenu}>茶会・イベント</Link>
            <Link smooth to="/#about" className="my-4 no-underline hover:underline" onClick={toggleMenu}>道場の紹介</Link>
            <Link smooth to="/#hours" className="my-4 no-underline hover:underline" onClick={toggleMenu}>稽古日・時間</Link>
            <Link smooth to="/#belongings" className="my-4 no-underline hover:underline" onClick={toggleMenu}>稽古の持物</Link>
            <Link smooth to="/#access" className="my-4 no-underline hover:underline" onClick={toggleMenu}>アクセス</Link>
            {/* モバイルメニューのInstagramアイコン */}
            <a href="https://www.instagram.com/seiryo_kishida/" target="_blank" rel="noopener noreferrer" className="mt-4 text-7xl text-pink-600 hover:text-pink-700">
              <FaInstagram />
            </a>
          </div>
        )}
      </header>

      {/* バナー */}
      <Link to="https://ihaku.jp">
        <div className="bg-purple-800 opacity-95 text-white glowing-text flex flex-col h-full pt-2 md:pt-4 pb-2 md:pb-4 text-center justify-center items-center mt-14 md:mt-16">
          <h1 className="tracking-widest font-semibold md:text-2xl flex items-center">
            江戸千家渭白流 公式サイトはこちら
            <span className="ml-4 text-xs md:text-xl hover:text-gray-600 transition duration-300">{'>>'}</span>
          </h1>
        </div>
      </Link>
    </>
  );
}

export default Header;
