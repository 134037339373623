import React from 'react';

function Footer() {
  return (
    <footer className="bg-black opacity-90 text-white glowing-text p-2 md:p-4">
      <div className="container mx-auto text-center max-w-screen-xl text-xs md:text-base">
        <p>&copy; 2025 静亮庵 . All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
