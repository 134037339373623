import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function Trial() {
  return (
    <section id="trial" className="container mx-auto max-w-screen-lg pt-36 pb-12">
      <h2 className="glowing-text font-bold text-3xl md:text-5xl mb-6 text-center">無料体験入門実施中</h2>
      <div className="p-8 bg-yellow-50 shadow-md rounded-md">
        <p className="md:text-lg">
          茶道の世界に初めて触れる方のために、無料体験入門を実施しています。下記の詳細をご覧いただき、ぜひご参加ください。
        </p>
        <ul className="list-disc p-6 space-y-2">
          <li><strong>料金：</strong>無料</li>
          <li><strong>日時：</strong>メールにてご相談</li>
          <li><strong>場所：</strong><Link to="#access" className="text-blue-600 underline">道場</Link></li>
          <li><strong>服装：</strong>自由（白いソックスのみご持参ください）</li>
          <li><strong>持ち物：</strong>とくになし</li>
          <li><strong>内容：</strong>入門者のお稽古にお客さまとしてご参加いただき、お茶とお菓子をお召し上がりください<br/>
            <span className="text-sm text-gray-600">※お点前の体験はございません</span>
          </li>
          <li><strong>申込方法：</strong>メール（ info@edosenkewakabakai.com ）に以下をご記載ください
            <ol className='list-decimal mt-2 ml-2'>
              <li>氏名（フルネーム）</li>
              <li>住所（最寄り駅などでも可）</li>
              <li>希望日時</li>
              <li>質問事項など（任意）</li>
            </ol>
          </li>
        </ul>
        <p className="text-sm text-red-600">※入門を検討している方のみご参加ください</p>
      </div>
    </section>
  );
}

export default Trial;
